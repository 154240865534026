export const desserts = [
    {
      "id": 1,
      "name": "McFlurry with OREO Cookies",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-oreo-mcflurry-snack:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-oreo-mcflurry-snack:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "The McDonald’s McFlurry® with OREO® Cookies is a popular combination of creamy vanilla soft serve with crunchy pieces of OREO® cookies! Available in regular and snack sizes. There are 510 calories in a regular size OREO® McFlurry® at McDonald's. Check out all the McFlurry® flavors on the McDonald's Desserts & Shakes menu. ",
      "price": 8
    },
    {
      "id": 2,
      "name": "McFlurry with M&M'S Candies",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mm-mcflurry-snack:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mm-mcflurry-snack:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "The McDonald’s M&M ® McFlurry® is a sweet, creamy M&M’S® dessert of vanilla soft serve with M&M’S® chocolate candies swirled in. Available in Regular Size and Snack Size.",
      "price": 8
    },
    {
      "id": 3,
      "name": "Vanilla Cone",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Vanilla-Reduced-Fat-Ice-Cream-Cone:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Vanilla-Reduced-Fat-Ice-Cream-Cone:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "Enjoy our creamy vanilla soft serve in a crispy cone! It's the perfect sweet treat in addition to any McDonald's meal or on its own! There are 200 calories in McDonald's soft serve cones! ",
      "price": 5
    },
    {
      "id": 4,
      "name": "Chocolate Shake",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Chocolate-McCafe-Shake-Medium-1:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Chocolate-McCafe-Shake-Medium-1:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "Try the McDonald’s Chocolate Shake, the perfect sweet treat for any time of the day. Our chocolate shake is made with delicious soft serve, chocolate syrup and finished off with a creamy whipped topping. McDonald's Chocolate Shake is available in small, medium and large. ",
      "price": 11
    },
    {
      "id": 5,
      "name": "Vanilla Shake",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Vanilla-McCafe-Shake-Medium-1:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Vanilla-McCafe-Shake-Medium-1:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "McDonald's vanilla shake is made with our creamy vanilla soft serve and topped with whipped topping for a cool, tasty treat. Available in small, medium, and large. There are 510 calories in a small vanilla shake at McDonald's. ",
      "price": 10
    },
    {
      "id": 6,
      "name": "Strawberry Shake",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Strawberry-McCafe-Shake-Medium-1:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Strawberry-McCafe-Shake-Medium-1:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "McDonald's Strawberry Shake is made with creamy vanilla soft serve, blended with strawberry syrup and topped with whipped topping. There are 530 calories in a small Strawberry Shake at McDonald's. Available in small, medium, and large. ",
      "price": 9
    },
    {
      "id": 7,
      "name": "Hot Fudge Sundae",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Hot-Fudge-Sundae:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Hot-Fudge-Sundae:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "Our classic hot fudge sundae is made with creamy vanilla soft serve and smothered in chocolaty hot fudge topping. There are 330 calories in a Hot Fudge Sundae at McDonald's. ",
      "price": 8
    },
    {
      "id": 7,
      "name": "Hot Caramel Sundae",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Hot-Caramel-Sundae-1:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Hot-Caramel-Sundae-1:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "Treat yourself to a Hot Caramel Sundae at McDonald's! This Caramel Sundae combines creamy vanilla soft serve and warm, buttery caramel topping. ",
      "price": 14
    },
    {
      "id": 8,
      "name": "Baked Apple Pie",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Baked-Hot-Apple-Pie-1:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Baked-Hot-Apple-Pie-1:product-header-mobile?wid=768&hei=443&dpr=off"
        }
      ],
      "desc": "McDonald's Baked Apple Pie recipe features 100% American-grown apples, and a lattice crust baked to perfection and topped with sprinkled sugar. There are 230 calories in McDonald's apple pie. Pair it with a Hot Caramel Sundae for your own twist on Apple Pie A-La-Mode! Order a Baked Apple Pie",
      "price": 11
    },
    {
      "id": 9,
      "name": "Chocolate Chip Cookie",
      "img": [
        {
          "sm": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Chocolate-Chip-Cookie-mcd:1-4-product-tile-desktop"
        },
        {
          "lg": "https://s7d1.scene7.com/is/image/mcdonalds/t-mcdonalds-Chocolate-Chip-Cookie-mcd:product-header-mobile?wid=768&hei=441&dpr=off"
        }
      ],
      "desc": "McDonald's classic chocolate chip cookie, loaded with chocolate chips. It's a soft baked cookie and warmed to perfection. ",
      "price": 12
    }
  ]